<template>
  <section id="edit-campaign-data">
    <b-overlay
      variant="transparent"
      :show="updatingData"
    >
      <b-card>
        <b-card-header>
          <b-card-title>
            {{
              $t("advertising.ExternalServerTitle")
            }}
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              lg="12"
              class="ml-2 mb-2"
            >
              <validation-observer ref="accountRules">
                <b-form @submit.prevent="validationFormInfo()">
                  <!-- form input -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="external"
                    rules="url"
                  >
                    <b-form-group
                      label-for="external"
                      :label="$t('advertising.ExternalServerUrl')"
                      :invalid-feedback="$t('url inválida')"
                    >
                      <b-form-input
                        id="external"
                        v-model="externalUrl"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="urlMovil"
                    rules="url"
                  >
                    <b-form-group
                      label-for="urlMovil"
                      :label="$t('urlServExt')"
                      :invalid-feedback="$t('url inválida')"
                    >
                      <b-form-input
                        id="urlMovil"
                        v-model="externalAdsUrlMobile"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="urlTv"
                    rules="url"
                  >
                    <b-form-group
                      label-for="urlTv"
                      :label="$t('urlServExtTv')"
                      :invalid-feedback="$t('url inválida')"
                    >
                      <b-form-input
                        id="urlTv"
                        v-model="externalAdsUrlTv"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('advertising.ExternalServerTag')"
                  >
                    <b-form-input
                      v-model="externalTag"
                      autocomplete="new-password"
                      class="mr-1"
                    />
                  </b-form-group>
                  <b-button
                    type="submit"
                    variant="success"
                  >
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>
<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { showToast, messageError } from '@/store/functions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'

import axios from '@axios'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,

  },
  props: {
    info: {
      default: [],
    },
  },
  data() {
    return {
      required,
      externalUrl: null,
      externalTag: null,
      externalAdsUrlMobile: null,
      externalAdsUrlTv: null,
      updatingData: false,
      userData: getUserData(),
    }
  },
  watch: {
    info() {
      this.externalUrl = this.info.externalAdsUrl
      this.externalTag = this.info.externalAdsTag
      this.externalAdsUrlMobile = this.info.externalAdsUrlMobile
      this.externalAdsUrlTv = this.info.externalAdsUrlTv
    },
  },
  mounted() {
    // this.getCampaignData();
  },
  methods: {
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateExternalData()
          } else {
            // reject();
          }
        })
      })
    },
    updateAdsPenetration() {
      // console.log('ads')
    },
    getCampaignData() {
      const { id } = this.$route.params
      const query = `
          query {
            allCampaigns(id: "${id}",client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  externalAdsUrl
                  externalAdsTag
                  externalAdsUrlMobile
                  externalAdsUrlTv
                }
              }
            }
          }
        `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        if (res.data.data.allCampaigns.edges.length) {
          const dataRes = res.data.data.allCampaigns.edges[0]
          this.externalUrl = dataRes.node.externalAdsUrl
          this.externalTag = dataRes.node.externalAdsTag
          this.externalAdsUrlMobile = dataRes.node.externalAdsUrlMobile
          this.externalAdsUrlTv = dataRes.node.externalAdsUrlTv
        }
      })
    },
    updateExternalData() {
      const query = `
          mutation ($url: String, $tag: String, $mobile: String, $tv: String, $id: ID!) {
            updateCampaign(id: $id, input: {
              externalAdsUrl: $url,
              externalAdsTag: $tag,
              externalAdsUrlMobile: $mobile,
              externalAdsUrlTv: $tv
            }){
              campaing {
                id
                name
                description
              }
            }
          }
        `
      const variables = {
        id: this.$route.params.id,
        tag: this.externalTag,
        url: this.externalUrl,
        mobile: this.externalAdsUrlMobile,
        tv: this.externalAdsUrlTv,
      }
      axios
        .post('', { query, variables })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
        })
    },
  },
}
</script>
