<template>
  <section id="edit-campaign">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t('Campañas') }}</h1>
        <h5 class="text-primary">
          {{ $t("advertising.EditCampaign") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <router-link to="/advertising/campaigns">
          <b-button variant="danger">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-tabs pills>
      <!-- Resource -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("editContent.title2") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <edit-campaign-data-card
              v-show="mostrarDatos"
              :info="allCampaigns"
              :sponsor="isSponsor"
            />
          </b-col>
        </b-row>
      </b-tab>

      <b-tab v-if="!internal">
        <template #title>
          <feather-icon icon="DatabaseIcon" />
          <span>{{ $t('advertising.ExternalServerTitle') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <edit-external-ad-server
              v-show="mostrarDatos"
              :info="allCampaigns"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>{{ $t('code.C&C') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <edit-cat-cont
              v-show="mostrarDatos"
              :info="allCampaigns"
              :sponsor="isSponsor"
              @updateData="getData()"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>{{ $t('fechas') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <edit-campaign-dates
              v-show="mostrarDatos"
              :info="allCampaigns"
            />
          </b-col>
        </b-row>
      </b-tab>
      <!-- GEOBLOQUEO -->
      <b-tab v-if="geoipbloking">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>{{ $t('zones.title') }}</span>
        </template>
        <b-card>
          <geoblocking :type-element="'CAM'" />
        </b-card>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import {
  BButton, BRow, BCol, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { isUserInGroup, messageError } from '@/store/functions'
import * as constants from '@core/utils/constants'
import { getUserData } from '@/auth/utils'
import geoblocking from '@/views/components/geoblocking.vue'
import EditCampaignDataCard from './cards/EditCampaignDataCard.vue'
import EditExternalAdServer from './cards/EditExternalAdServer.vue'
import EditCampaignDates from './cards/EditCampaignDates.vue'
import EditCatCont from './cards/EditCampaignCatCont.vue'

export default {
  components: {
    BButton,
    BRow,
    geoblocking,
    BCol,
    EditCampaignDataCard,
    EditCatCont,
    BCard,
    EditExternalAdServer,
    EditCampaignDates,
    BTabs,
    BTab,
  },
  data() {
    return {
      allCampaigns: null,
      internal: false,
      geoipbloking: false,
      userData: getUserData(),
      isSponsor: false,
      mostrarDatos: false,
    }
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.geoipbloking = this.userData?.profile?.client?.geoipbloking
    this.getData()
  },
  methods: {
    getData() {
      const { id } = this.$route.params
      const query = `
          query {
            allCampaigns(id:"${id}",client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  creator {
                    id
                  }
                  name
                  description
                  isActive
                  isPriority
                  interactivityAdsPenetration
                  contentsFillRate
                  externalAdsUrl
                  externalAdsTag
                  externalAdsUrlMobile
                  externalAdsUrlTv
                  externalsAdsPenetration
                  startDate
                  finalDate
                  campaingAd {
                    totalCount                  
                  }
                  categories{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }                  
                  contents{
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        if (res.data.data.allCampaigns.edges.length) {
          const campaigns = res.data.data.allCampaigns.edges[0].node
          let authorized = false
          if (this.isSponsor) {
            if (campaigns.creator !== null) {
              if (campaigns.creator.id === this.userData.id) authorized = true
            }
            if (!authorized) {
              this.$router.push({ name: 'misc-not-authorized' })
            }
          }
          if (
            campaigns.campaingAd.totalCount > 0
            || (campaigns.contentsFillRate === 1
              && campaigns.externalsAdsPenetration === 0)) {
            this.internal = true
          } else {
            this.internal = false
          }
          if (!authorized && this.isSponsor) this.mostrarDatos = false
          else this.mostrarDatos = true

          this.allCampaigns = campaigns
        }
      }).catch(err => {
        console.log(err)
      })
    },
    showToast(type) {
      if (type === 1) {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('code.updateCamp'),
            icon: 'CoffeeIcon',
            variant: 'success',
            position: 'bottom-right',
          },
        })
      } else {
        this.$toast.error({
          component: ToastificationContent,
          position: 'bottom-right',
          closeOnClick: true,
          props: {
            title: this.$t('code.errorUpdate'),
            icon: 'fas fa-times',
            variant: 'error',
            position: 'bottom-right',
          },
        })
      }
    },
  },
}
</script>
<style scoped>
#edit-campaign {}
</style>
